import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { Authz } from "@practice/authz";
import { PackageInstanceCardCyclesInner } from "@practice/sdk";
import { SanitizedAccountType } from "api-services/definitions/integrations";
import { postCreatePayment } from "api-services/definitions/products";
import { useApi } from "api-services/endpoints";
import axios from "axios";
import classNames from "classnames";
import { format } from "date-fns";
import { compact, isEmpty } from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import { GetServerSideProps, NextPage } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import { getFuego } from "swr-firebase";

import useFlow from "@hooks/use-flow";
import useFormTemplates from "@hooks/use-form-templates";
import useIsMobileWebView from "@hooks/use-is-mobile-webview";
import useLogger from "@hooks/use-logger";
import { usePublicPackageInstance } from "@hooks/use-package-instance";
import usePersistentState from "@hooks/use-persistent-state";
import useScheduler from "@hooks/use-scheduler";
import useSnackbar from "@hooks/use-snackbar";
import useToggle from "@hooks/use-toggle";
import useWindowSize from "@hooks/use-window-size";
import analytics from "@lib/analytics";
import { AccountType } from "@lib/data/schemas/account";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { CouponType } from "@lib/data/schemas/coupon";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { ProductType } from "@lib/data/schemas/product";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { db } from "@lib/firebase-admin-config";
import { getInvoiceBoundaryErrors } from "@lib/invoices";
import {
  calculateInitialBookMonth,
  getCurrentCycle,
  getFullAndAvailableCyclesFromNow,
} from "@lib/models/package-instances/utils";
import {
  ContactFormInfoType,
  MetaInfoType,
  SanitizedUserType,
  TaxType,
} from "@lib/shared-types";
import { withReminderSMS as withSmartActionReminderSMS } from "@lib/smart-actions/utils";
import { getCoachBaseUrl } from "@lib/utils";
import catchErrorClient from "@lib/utils/catch-error-client";
import { contactForm } from "@lib/utils/contacts";
import { trackFormSubmitted } from "@lib/utils/form-analytics";
import { getDocData } from "@lib/utils/get-doc-data";
import { getMetaDescription } from "@lib/utils/meta-data";
import {
  getCompanyMetaInformation,
  getContactFormInfo,
  getDocumentBySlugOrId,
  getServerSidePropsHelper,
} from "@lib/utils/slug-helper";

import AppointmentContactForm from "@components/AppointmentContactForm";
import ConfirmRescheduleCancelAppointmentModal from "@components/AppointmentTypeForm/ConfirmRescheduleCancelAppointmentModal";
import SmallBanner from "@components/Banner/SmallBanner";
import { Button } from "@components/Button";
import BookingCalendar from "@components/Client/BookingFlow/BookingCalendar";
import ClientContainerLayout from "@components/Client/ClientContainerLayout";
import ClientFooterLayout from "@components/Client/ClientFooterLayout";
import ClientLayout from "@components/Client/ClientLayout";
import SubmitButton, { VARIANTS } from "@components/Client/SubmitButton";
import {
  CommunicationField,
  ContactInfoForm,
} from "@components/ContactInfoForm";
import BookingSchedulerDetails, {
  BookingSchedulerDetailsProps,
} from "@components/DisplayDetails/BookingSchedulerDetails";
import DetailsWrapper from "@components/DisplayDetails/DetailsWrapper";
import IconWithDetails from "@components/DisplayDetails/IconWithDetails";
import MemberSelectorModal from "@components/DisplayDetails/MemberSelectorModal";
import ProductDetails from "@components/DisplayDetails/ProductDetails";
import { ErrorMessage } from "@components/ErrorMessage";
import { FlowStep, FlowWithSteps } from "@components/Flow";
import AlertIcon from "@components/Icons/AlertIcon";
import CalendarIcon from "@components/Icons/CalendarIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import LeftArrowIcon from "@components/Icons/LeftArrowIcon";
import RescheduleIcon from "@components/Icons/RescheduleIcon";
import { IntakeFormFields } from "@components/IntakeForm/IntakeFormFields";
import LoadingSpinner from "@components/LoadingSpinner";
import MetaHead from "@components/MetaHead";
import { PaymentRequestForm } from "@components/PaymentFlow/PaymentRequestForm";

interface ReschedulingNoticeProps {
  userId: string;
  appointmentId: string;
}

export const ReschedulingNotice: React.FC<ReschedulingNoticeProps> = ({
  userId,
  appointmentId,
}) => {
  // do not listen to updates because it causes data to update before redirection to appointment page
  // const { appointment } = useAppointment(userId, appointmentId, { listen: false, revalidateOnFocus: false })
  // cannot prevent automatic revalidation using the hook above

  const router = useRouter();
  const mobileSession = useIsMobileWebView();

  const { iframe } = router.query;

  const [appointment, setAppointment] = useState();

  useEffect(() => {
    getFuego()
      .db.collection(`users/${userId}/appointments`)
      .doc(appointmentId)
      .get()
      .then((doc) => setAppointment(doc.data()));
  }, [appointmentId, userId]);

  if (!appointment) return null;

  const params = new URLSearchParams({
    ...(iframe && { iframe }),
  });

  return (
    <div
      className={classNames(
        "w-full bg-foreground/7 text-foreground rounded-md py-4 px-6 text-sm mt-4 sm:flex justify-between items-center"
      )}
    >
      <div
        className={classNames(
          "flex items-center",
          (iframe || mobileSession) && "mb-4"
        )}
      >
        <RescheduleIcon className="shrink-0 hidden sm:block" />
        <p className="w-full sm:ml-6">
          <strong className="font-medium">
            You are currently rescheduling your appointment on
          </strong>{" "}
          <br />
          {format(appointment.start.toDate(), "PPPP")}.{" "}
          {format(appointment.start.toDate(), "p")} -{" "}
          {format(appointment.end.toDate(), "p")}
        </p>
      </div>
      <Link
        href={`/me/${userId}/appointments/${appointmentId}?${params.toString()}`}
        passHref
        legacyBehavior
      >
        <Button
          small
          component="a"
          className={classNames("mt-4 sm:mt-0", VARIANTS.primary)}
        >
          Cancel
        </Button>
      </Link>
    </div>
  );
};

const ViewAvailabilityScreen: React.FC<BookingSchedulerDetailsProps> = ({
  product,
  availability,
  taxType,
  packageInstanceId,
  owner,
  members,
  memberId,
}) => {
  const { nextStep, timeZone } = useFlow();
  const validCurrentMemberId = members?.find((m) => m.id === memberId);
  return (
    <div className="max-w-sm flex flex-col justify-center mb-14 sm:w-[400px]">
      <BookingSchedulerDetails
        product={product}
        availability={availability}
        taxType={taxType}
        hideProduct={!!packageInstanceId}
        owner={owner}
        members={members}
        canChangeMember={false}
        timeZone={timeZone}
      />
      <SubmitButton
        actionTitle="View availability"
        className="mx-auto w-auto mt-6"
        onAction={() => {
          nextStep({ step: 1, ...(validCurrentMemberId && { memberId }) });
        }}
        size="regular"
        type="button"
      />
    </div>
  );
};

interface AvailabilityInfoProps {
  style?: string;
  availability: SchedulerType;
  availabilityError: unknown;
  inactive: boolean;
  showAvailability: boolean;
  product?: ProductType;
  taxType?: TaxType;
  members?: SanitizedAccountType[];
  owner?: SanitizedAccountType;
  canChangeMember?: boolean;
  timeZone?: string;
}

const AvailabilityInfo: React.FC<AvailabilityInfoProps> = ({
  availability,
  availabilityError,
  inactive,
  showAvailability,
  product,
  taxType,
  members,
  owner,
  canChangeMember,
  timeZone,
}) => {
  const altFirstIcon = (
    <IconWithDetails
      icon={availabilityError || inactive ? <AlertIcon /> : <ClockIcon />}
      title={
        availabilityError
          ? "Calendar currently unavailable"
          : inactive
          ? "Calendar currently disabled"
          : `${availability.duration}-minute appointment`
      }
      subtitle={DateTime.local().setZone(timeZone).offsetNameLong}
      iconClassNames={classNames(
        inactive && "text-accent/50 bg-accent/10",
        !!availabilityError && "text-error/50 bg-error/10"
      )}
    />
  );
  return (
    <div className="mb-6 max-w-sm sm:w-[400px]">
      {availability && showAvailability && (
        <BookingSchedulerDetails
          availability={availability}
          taxType={taxType}
          product={product}
          altFirstIcon={altFirstIcon}
          hideProduct
          members={members}
          owner={owner}
          canChangeMember={canChangeMember}
          timeZone={timeZone}
        />
      )}
    </div>
  );
};

interface DateAndTimeSlotsProps {
  isMobileView: boolean;
  onGoBack: () => void;
  availableSlots: {
    prettyDate: DateTime;
  };
}

const DateAndTimeSlots: React.FC<DateAndTimeSlotsProps> = ({
  availableSlots,
  isMobileView,
  onGoBack,
  children,
}) => {
  return (
    <div
      className={classNames(
        "sm:col-span-12 md:col-span-4 mb-12 md:mb-4 availability-calendar",
        !availableSlots
          ? "border-2 rounded-lg border-dashed border-foreground/20 grid place-content-center place-items-center p-4 text-center"
          : null
      )}
    >
      {availableSlots ? (
        <>
          {isMobileView ? (
            <div className="flex items-center mb-4">
              <span className="mr-3 text-foreground" onClick={onGoBack}>
                <LeftArrowIcon />
              </span>
              <h3 className="font-medium text-xl text-foreground">
                {moment(availableSlots.prettyDate).format("dddd, MMM D, YYYY")}
              </h3>
            </div>
          ) : (
            <h3 className="font-medium text-xl mb-2 text-foreground">
              {moment(availableSlots.prettyDate).format("dddd, MMM D, YYYY")}
            </h3>
          )}
          {children}
        </>
      ) : (
        <>
          <LeftArrowIcon className="w-5 text-black-ink mb-3 text-foreground" />
          <h3 className="text-base text-black-ink font-medium mb-1 text-foreground">
            Pick a date to start
          </h3>
          <p className="text-foreground/50 mb-6">
            Available hours will appear here once selected
          </p>
          <div>
            <div className="flex items-center">
              <span className="inline-block bg-blue-700 p-1 rounded-full mr-2" />
              <span className="text-sm text-foreground">Morning</span>
            </div>
            <div className="flex items-center">
              <span className="inline-block bg-action-500 p-1 rounded-full mr-2 " />
              <span className="text-sm text-foreground">Afternoon</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface AvailableSlotsProps {
  user: SanitizedUserType;
  availabilityId: string;
  packageInstanceId?: string;
  clientId: string;
  type: string;
  title: string;
  rescheduling: boolean;
  availableSlots: unknown;
  iframe: string;
  members?: SanitizedAccountType[];
}

const AvailableSlots: React.FC<AvailableSlotsProps> = ({
  type,
  title,
  availableSlots,
  members,
}) => {
  const [ISOslotLoading, setISOslotLoading] = useState();
  const { nextStep } = useFlow();

  const getAvailableSlots = (type: string) =>
    availableSlots
      ? availableSlots.slots?.filter(({ pretty }) =>
          pretty.toLowerCase().includes(type)
        )
      : null;
  const slotsByType = getAvailableSlots(type);

  const goToNextStep = (
    ISOslot: string,
    availableMembers: SanitizedAccountType[]
  ) => {
    setISOslotLoading(ISOslot);

    const params: any = {
      isoSlot: ISOslot,
    };

    if (availableMembers) {
      if (availableMembers.length === 1) {
        params.memberId = availableMembers[0].id;
      } else {
        const sortedIds = members!.map((member) => member.id);
        params.memberIds = availableMembers
          .sort((a, b) => {
            // Compare them by their index in the members array, since that one is sorted
            return sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id);
          })
          .map((member) => member.id);
      }
    }

    nextStep(params);
  };

  return (
    <div className="mb-8">
      <div
        className={`mx-0.5 inline-block ${
          type === "am" ? "bg-blue-700" : "bg-action-500"
        } p-1 rounded-full`}
      ></div>
      <div className="inline-block text-md ml-2 font-medium text-foreground">
        {title}
      </div>

      <div className="mt-4">
        {availableSlots && slotsByType.length
          ? slotsByType.map(({ pretty, ISOslot, members }) => (
              <a
                onClick={() => goToNextStep(ISOslot, members)}
                key={pretty}
                data-heap-event-name="scheduler_client_chooses_time"
                className="appointment-time text-foreground bg-foreground/7 hover:bg-foreground/20 scheduler-slot transition-all block font-medium text-center w-full my-2  p-4 rounded-lg  cursor-pointer"
              >
                {ISOslot === ISOslotLoading ? (
                  <LoadingSpinner
                    variant="transparent"
                    width="25"
                    height="40"
                    className="mx-auto"
                  />
                ) : (
                  <>{pretty}</>
                )}
              </a>
            ))
          : null}

        {availableSlots && !slotsByType.length ? (
          <div className="text-sm text-foreground/50">No slots available.</div>
        ) : null}
      </div>
    </div>
  );
};

interface TimeSlotSelectionFormProps {
  user: SanitizedUserType;
  rescheduling?: string;
  iframe: string;
  availabilityId: string;
  availability: SchedulerType;
  schedulerOwner: SanitizedAccountType;
  clientId: string;
  product?: ProductType;
  taxType?: TaxType;
  members?: SanitizedAccountType[];
  clientOwnerId?: string;
  packageInstance?: PackageInstanceType;
  currentMemberId?: string;
  availablePackageInstanceCycles?: PackageInstanceCardCyclesInner[] | null;
  isBookingInFutureCycles?: boolean;
}

const TimeSlotSelectionForm: React.FC<TimeSlotSelectionFormProps> = ({
  user,
  availabilityId,
  availability,
  schedulerOwner,
  iframe,
  rescheduling,
  clientId,
  product,
  taxType,
  members,
  clientOwnerId,
  packageInstance,
  currentMemberId,
  availablePackageInstanceCycles,
  isBookingInFutureCycles = false,
}) => {
  const initialISODate = moment(availablePackageInstanceCycles?.[0]?.start)
    .utc()
    .toISOString();
  const initialBookMonth = calculateInitialBookMonth(initialISODate);
  const [date, setDate] = useState(initialBookMonth);

  useEffect(() => {
    setDate(initialBookMonth);
  }, [initialBookMonth]);

  const packageInstanceId = packageInstance?.id;

  const now = DateTime.local();
  const localTimeZone = now.zoneName;
  const {
    sharedData: scheduleFlow,
    setSharedData: setSchedulerFlow,
    setTimeZone,
    timeZone: flowTimeZone,
  } = useFlow();
  const timeZone = flowTimeZone || localTimeZone;
  const memberId = scheduleFlow?.memberId;
  const sanitizedDateFromBookingCalendar = DateTime.local()
    .setZone(timeZone)
    .plus({
      months: date,
    });

  // Roster scheduler turned into round robin in a packageInstance
  const packageMemberSettingsIds = useMemo(() => {
    const schedulerItem = packageInstance?.items.find(
      (item) => item.schedulerId === availabilityId
    );
    if (schedulerItem?.memberIds?.length) return schedulerItem.memberIds;
  }, [availabilityId, packageInstance]);

  const canChangeMember =
    availability.roundRobin?.memberSwitching || !!packageMemberSettingsIds;

  const {
    data: availabilityData,
    getSlotDays,
    loading,
    inactive,
    error: availabilityError,
  } = useScheduler(
    user.id,
    availabilityId,
    sanitizedDateFromBookingCalendar,
    clientId,
    timeZone,
    packageInstanceId,
    !!availability.roundRobin,
    rescheduling
  );

  const sortedMembers = useMemo(() => {
    if (!members) return undefined;

    if (availability.roundRobin?.priority === "assignee" && clientOwnerId) {
      const clientOwnerIndex = members?.map((m) => m.id).indexOf(clientOwnerId);
      if (clientOwnerIndex !== -1) {
        const copy = [...members];
        const clientOwner = copy?.splice(clientOwnerIndex, 1)[0];
        return [clientOwner, ...copy];
      }
    }
    return members;
  }, [availabilityId, clientOwnerId, members, packageInstance]);

  useEffect(() => {
    if (memberId !== undefined) return;
    const getMemberId = () => {
      if (packageMemberSettingsIds) {
        return packageMemberSettingsIds[0];
      } else if (availability.roundRobin?.roster) {
        return clientOwnerId;
      } else if (sortedMembers) {
        return rescheduling && currentMemberId
          ? currentMemberId
          : sortedMembers[0].id;
      }
    };

    const newMemberId = getMemberId();
    if (newMemberId) {
      setSchedulerFlow({
        ...scheduleFlow,
        memberId: newMemberId,
      });
    }
  }, [
    availability,
    memberId,
    sortedMembers,
    rescheduling,
    currentMemberId,
    clientOwnerId,
    schedulerOwner,
    packageMemberSettingsIds,
  ]);

  const recipient = schedulerOwner ?? user;
  const marginBottom = iframe ? "sm:mb-4" : "sm:mb-24";
  const showAvailability = !iframe || iframe.includes("availability");
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState();
  const { width } = useWindowSize();
  const isMobile = width && width < 768;
  const [mobileCurrentScreen, setMobileCurrentScreen] = useState(null);

  useEffect(() => {
    if (scheduleFlow?.memberId !== undefined) {
      handleClearSelectedDate();
    }
  }, [scheduleFlow?.memberId]);

  const handleClearSelectedDate = () => {
    setSelectedDate(null);
    setAvailableSlots(undefined);
  };

  const handleSelectCalendarDate = (date: Date, availableSlots: unknown) => {
    setSelectedDate(date);
    setAvailableSlots(availableSlots);

    if (isMobile) {
      setMobileCurrentScreen("slots");
    }
  };

  const handleGoBack = () => {
    // Reset selected date and available slots
    handleClearSelectedDate();
    if (isMobile) {
      setMobileCurrentScreen("calendar");
    }
  };

  const finalMembers = (sortedMembers || members)?.filter(
    (m) => !packageMemberSettingsIds || packageMemberSettingsIds?.includes(m.id)
  );

  return (
    <>
      <AvailabilityInfo
        availability={availability}
        availabilityError={availabilityError}
        inactive={inactive}
        showAvailability={showAvailability}
        product={product}
        taxType={taxType}
        members={finalMembers}
        owner={schedulerOwner}
        canChangeMember={canChangeMember}
        timeZone={timeZone}
      />
      {isBookingInFutureCycles && !!availablePackageInstanceCycles && (
        <SmallBanner
          variant="white-label"
          className="max-w-sm sm:w-[400px] mb-6"
          items={[
            {
              Icon: InfoIcon,
              text: `All sessions for the current cycle are fully booked. You are now scheduling appointments for the next cycle, starting ${moment(
                availablePackageInstanceCycles?.[0]?.start
              )
                .utc()
                .format("MMM Do")}`,
            },
          ]}
        />
      )}
      {loading && !availabilityError && !inactive ? (
        <div
          className={classNames(
            "w-full flex justify-center mt-4",
            marginBottom
          )}
        >
          <LoadingSpinner
            variant="transparent"
            width="70"
            height="70"
            className="mx-auto"
          />
        </div>
      ) : (availabilityError && !availabilityData) || inactive ? (
        <div className={classNames("w-full flex justify-center", marginBottom)}>
          <AppointmentContactForm
            recipientAccountId={recipient.id}
            recipientName={recipient.firstName}
          />
        </div>
      ) : (
        <div
          className={classNames(
            "w-full sm:grid grid-cols-12 gap-4",
            marginBottom
          )}
        >
          {mobileCurrentScreen === "slots" ? null : (
            <div className="sm:col-span-12 md:col-span-8 mb-4">
              <BookingCalendar
                slotDays={getSlotDays()}
                selectedDate={selectedDate}
                onSelectCalendarDate={handleSelectCalendarDate}
                date={date}
                setDate={setDate}
                roster={!!availability.roundRobin?.roster}
                timeZone={timeZone}
                onChangeTimeZone={(value: string) => {
                  setTimeZone(value);
                  handleClearSelectedDate();
                }}
              />
            </div>
          )}
          {mobileCurrentScreen === "calendar" ? null : (
            <DateAndTimeSlots
              availableSlots={availableSlots}
              isMobileView={mobileCurrentScreen === "slots"}
              onGoBack={handleGoBack}
            >
              <AvailableSlots
                user={user}
                availabilityId={availabilityId}
                title="Morning"
                type="am"
                rescheduling={!!rescheduling}
                iframe={iframe}
                packageInstanceId={packageInstanceId}
                clientId={clientId}
                availableSlots={availableSlots}
                members={sortedMembers}
              />
              <AvailableSlots
                user={user}
                availabilityId={availabilityId}
                title="Afternoon"
                type="pm"
                rescheduling={!!rescheduling}
                iframe={iframe}
                packageInstanceId={packageInstanceId}
                clientId={clientId}
                availableSlots={availableSlots}
                members={sortedMembers}
              />
            </DateAndTimeSlots>
          )}
        </div>
      )}
    </>
  );
};

export const getAppointmentDateAndTime = (startTime, endTime, timeZone) => {
  const now = DateTime.local().setZone(timeZone);
  const localTimeZone = now.offsetNameShort;
  const prettyStartTime = startTime?.setZone(timeZone).toFormat("h:mm a");
  const prettyEndTime = endTime?.setZone(timeZone).toFormat("h:mm a");
  const date = startTime?.setZone(timeZone).toFormat("cccc LLLL d, yyyy");
  const time = `${prettyStartTime} – ${prettyEndTime} ${localTimeZone}`;
  return { date, time };
};

interface ClientBookingFormProps {
  register: UseFormReturn["register"];
  handleSubmit: UseFormReturn["handleSubmit"];
  control: UseFormReturn["control"];
  watch: UseFormReturn["watch"];
  setValue: UseFormReturn["setValue"];
  errors: UseFormReturn["formState"]["errors"];
  loading: boolean;
  isSubmitting: boolean;
  rescheduling?: string;
  userId: string;
  isEmbedded: boolean;
  packageInstanceId: string;
  form: unknown;
  availability: SchedulerType;
  onSubmitForm: unknown;
  product: unknown;
  contactInfoInitialValues: unknown;
  coach: SanitizedUserType;
  showPromoCodeOption: boolean;
}

const BookingForm: React.FC<ClientBookingFormProps> = ({
  form,
  loading,
  availability,
  onSubmitForm,
  isSubmitting,
  rescheduling,
  packageInstanceId,
  product,
  contactInfoInitialValues,
  userId,
  coach,
  isEmbedded,
  isoSlotLoaded = false,
}) => {
  const formMethods = useFormContext();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;
  const isProductFlow = product && !rescheduling && !packageInstanceId;
  const { smartActions, communication: schedComms, roundRobin } = availability;
  const { sharedData } = useFlow();
  const memberId = sharedData?.memberId;

  const getRRComms = () => {
    const hasRRMemberIds = schedComms?.some(
      (comm) => comm.roundRobinMemberIds?.length
    );
    return hasRRMemberIds
      ? schedComms?.filter(
          (comm) => comm.roundRobinMemberIds?.includes(memberId)
        )
      : schedComms;
  };

  const communication = roundRobin ? getRRComms() : schedComms;

  const outgoingCall =
    communication?.length >= 1
      ? !!communication.find((c) => c.value === "outgoing-call")
      : false;
  const withReminderSMS = withSmartActionReminderSMS(smartActions || []);
  const { value: rescheduleModalOpen, toggle: toggleRescheduleModal } =
    useToggle();
  const reschedulingOptions = availability?.reschedulingOptions;
  const [reason, setReason] = useState<string | undefined>();

  const formRef = useRef<HTMLFormElement | undefined>(null);

  const signerContact = {
    firstName: watch("firstName"),
    lastName: watch("lastName"),
    email: watch("email"),
  };

  // @TODO: set default number if exists
  useEffect(() => {
    if (contactInfoInitialValues) {
      const { firstName, lastName, email, formData } = contactInfoInitialValues;
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
      if (form) {
        setValue("form", formData);
      }
    }
  }, [contactInfoInitialValues, setValue, form]);

  useEffect(() => {
    if (communication?.length === 1 && roundRobin) {
      setValue("communication", communication[0]);
    }
  }, [communication, setValue, roundRobin]);

  const handleBlurEmail = async () => {
    const url = `/api/v1/users/${userId}/client-phone`;
    const email = watch("email");
    if (withReminderSMS && !isEmpty(email)) {
      const { data } = await axios.post(url, { email });
      const phone = data?.phoneNumber;
      setValue("phone", phone);
    }
  };

  const handleBookAppointment = (e: FormEvent<Element>) => {
    e.preventDefault();

    if (rescheduling && reschedulingOptions?.reasonRequired) {
      toggleRescheduleModal();
    } else {
      bookAppointment();
    }
  };

  const bookAppointment = (reason?: string) => {
    setReason(reason);
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const formItems = form?.items;

  return (
    <div
      className={classNames(
        "w-full sm:grid grid-cols-12",
        isEmbedded ? "mb-8" : "mb-24"
      )}
    >
      <div
        className={classNames(
          formItems
            ? "sm:col-start-2 sm:col-span-10"
            : "sm:col-start-4 sm:col-span-6"
        )}
      >
        {!isoSlotLoaded && (
          <LoadingSpinner
            variant="transparent"
            width="70"
            height="70"
            className="mx-auto"
          />
        )}
        {isoSlotLoaded && (
          <form
            data-name="Booking Form"
            ref={formRef}
            onSubmit={handleSubmit(onSubmitForm)}
          >
            {form?.description && (
              <div className="text-foreground/50 mb-8 md:mb-12 whitespace-pre-wrap">
                {form.description}
              </div>
            )}
            {!rescheduling && !packageInstanceId && (
              <>
                <ContactInfoForm
                  register={register}
                  errors={errors}
                  onBlurEmail={handleBlurEmail}
                  stackNames={!formItems}
                />
              </>
            )}

            {!rescheduling && (
              <>
                <CommunicationField
                  control={control}
                  errors={errors}
                  withReminder={withReminderSMS}
                  withOutgoingCall={outgoingCall}
                  communication={communication}
                />
              </>
            )}

            {!rescheduling && formItems && (
              <IntakeFormFields
                control={control}
                setValue={setValue}
                register={register}
                form={form}
                errors={errors}
                contact={signerContact}
                coachId={coach?.id}
              />
            )}

            {!loading && (
              <input
                type="hidden"
                name="availabilityId"
                id="availabilityId"
                value={availability.id}
              />
            )}
            <div className="mt-12 flex flex-col">
              {errors?.answers?.length >= 1 && (
                <ErrorMessage className="mb-1 items-center">
                  Please fill out all required fields
                </ErrorMessage>
              )}
              <SubmitButton
                actionTitle={
                  isProductFlow ? "Pay to confirm" : "Confirm Booking"
                }
                onAction={handleBookAppointment}
                errors={errors}
                className="self-center"
                isLoading={isSubmitting || !isoSlotLoaded}
                dataHeapEventName={
                  isProductFlow
                    ? "scheduler_client_payment_intent"
                    : "booked_appointment"
                }
              />
            </div>
            {rescheduling && reason && (
              <input type="hidden" name="reason" id="reason" value={reason} />
            )}
          </form>
        )}
      </div>
      {rescheduling && rescheduleModalOpen && (
        <ConfirmRescheduleCancelAppointmentModal
          action="reschedule"
          show
          toggleShow={toggleRescheduleModal}
          onAction={bookAppointment}
          reasonRequired={reschedulingOptions?.reasonRequired}
        />
      )}
    </div>
  );
};
interface ClientBookingConfirmationProps {
  user: SanitizedUserType;
  userId: string;
  availabilityId: string;
  availability: SchedulerType;
  schedulerOwner: SanitizedAccountType;
  product: unknown;
  rescheduling?: string;
  iframe: string;
  packageInstanceId: string;
  clientId: string;
  showPromoCodeOption: boolean;
  taxType?: TaxType;
  coupon?: CouponType;
  contact?: ContactFormInfoType;
  members?: AccountType[];
  clientParentId?: string;
}

const ClientBookingConfirmation: React.FC<ClientBookingConfirmationProps> = ({
  availability,
  availabilityId,
  schedulerOwner,
  user,
  userId,
  product,
  rescheduling,
  iframe,
  packageInstanceId,
  clientId,
  showPromoCodeOption,
  taxType,
  contact,
  members,
  clientParentId,
}) => {
  const now = DateTime.local();
  const router = useRouter();

  const { sharedData: scheduleFlow, timeZone } = useFlow();
  const memberIds = scheduleFlow?.memberIds;
  const isoSlot = scheduleFlow.isoSlot;
  const { logger } = useLogger("client-booking-confirmation");
  const mobileSession = useIsMobileWebView();
  const formMethods = useForm({
    mode: "onTouched",
    defaultValues: contactForm(contact),
  });
  const { watch } = formMethods;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState(null);

  const { previousStep, nextStep } = useFlow();

  const { persistentValue, persistentSetValue } = usePersistentState(
    "contactInfo",
    {}
  );

  const { persistentClear: clearSchedulerFlow } = usePersistentState(
    `schedulerFlow_${availabilityId}`,
    undefined,
    true
  );

  const { templates } = useFormTemplates(user?.id);

  const recipient = schedulerOwner ?? user;

  if (availability && templates) {
    const smartActions =
      availability?.smartActions?.filter(
        (item) => item.action.type === "show-form"
      ) || [];

    // @TODO: we don't support showing multiple forms in the scheduler.
    //        this code is to prevent issues in case of multiple forms.
    //        this condition needs to be validated
    const smartActionItem = smartActions[0] || null;

    if (smartActionItem && smartActionItem.action.resourceId && !form) {
      const relatedForm = templates
        .map((item) => ({ ...item, formTemplateId: item.id }))
        .find((item) => item.id === smartActionItem.action.resourceId);

      if (relatedForm && relatedForm.status === "active") {
        setForm(relatedForm);
      }
    }
  }
  const [bookingError, setBookingError] = useState<string | undefined>();

  const startTime = DateTime.fromISO(isoSlot);
  const endTime = startTime.plus({ minutes: availability?.duration });
  const { date, time } = getAppointmentDateAndTime(
    startTime,
    endTime,
    timeZone
  );

  const onSubmitForm = async (data, e) => {
    e.preventDefault();

    if (!data) {
      return;
    }

    const { firstName, lastName, email, answers, phone, communication } = data;
    const lowercaseEmail = email?.toLowerCase();

    if (isSubmitting) return; // to prevent multiple clicks
    setIsSubmitting(true);

    const formData = form && {
      ...form,
      items: form?.items?.map((item, i) => ({
        ...item,
        ...(answers && { answer: answers[i] }),
      })),
    };

    if (product && !rescheduling && (!packageInstanceId || !clientId)) {
      await persistentSetValue({
        ...data,
        formData,
      });

      try {
        if (form) trackFormSubmitted(form.items, answers);
        const data = {
          isoSlot,
          email,
          firstName,
          lastName,
        };
        nextStep(data);
      } catch (error: any) {
        const errorMessage = catchErrorClient(error, "An error occurred");
        logger.error(
          {
            error,
            organizationId: userId,
            availabilityId,
            isoSlot,
            email,
            firstName,
            lastName,
          },
          "Unable to submit the form"
        );
        setBookingError(
          error.response.data.error && error.response.data.clientError
            ? errorMessage
            : "An error occurred..."
        );
        setIsSubmitting(false);
      }
    } else {
      const reason = e.target.reason?.value;

      const dataToCreate = {
        email: lowercaseEmail,
        firstName,
        lastName,
        phone,
        timeZone: now.zoneName,
        form: formData,
        ...(rescheduling && { rescheduling }),
        ...(packageInstanceId && { packageInstanceId }),
        ...(clientId && { contactId: clientId }),
        communication,
        memberId: scheduleFlow.memberId,
        clientParentId,
        ...(rescheduling && reason && { reason }),
      };

      const url = `/api/users/${userId}/book/${availabilityId}/${isoSlot}`;

      try {
        const {
          data: { appointmentId },
        } = await axios.post(url, dataToCreate);

        if (!rescheduling) {
          if (form) trackFormSubmitted(form.items, answers);
          analytics.track({
            userId,
            event: "scheduler_client_booked_appointent",
            properties: {
              availabilityId,
              embedded: !!iframe,
            },
          });
        }
        if (packageInstanceId && clientId) {
          router
            .push(
              `${getCoachBaseUrl(user)}/clients/${
                clientParentId || clientId
              }/packages/${packageInstanceId}`
            )
            .then(async () => {
              await clearSchedulerFlow();
            });
        } else {
          const params = new URLSearchParams({
            showConfirmationEmail: true,
            ...(iframe && { iframe }),
          });
          router
            .push(
              `${getCoachBaseUrl(
                user
              )}/appointments/${appointmentId}?${params.toString()}`
            )
            .then(async () => {
              await clearSchedulerFlow();
            });
        }
      } catch (error: any) {
        const errorMessage = catchErrorClient(error, "An error occurred");
        logger.error(
          {
            error,
            organizationId: userId,
            rescheduling: !!rescheduling,
            withPackageInstance: !!packageInstanceId,
            availabilityId,
            isoSlot,
            ...(!!scheduleFlow.memberId && { memberId: scheduleFlow.memberId }),
            ...(packageInstanceId && { packageInstanceId }),
            ...(clientId && { clientId }),
            ...(clientParentId && { clientParentId }),
          },
          "Unable to create appointment"
        );
        setBookingError(`An error occurred: ${errorMessage}`);
        setIsSubmitting(false);
      }
    }
  };

  const renderContent = bookingError ? (
    <div className="mb-24">
      <AppointmentContactForm
        recipientAccountId={recipient.id}
        recipientName={recipient.firstName}
        firstName={watch("firstName")}
        lastName={watch("lastName")}
        email={watch("email")}
      />
    </div>
  ) : (
    <FormProvider {...formMethods}>
      <BookingForm
        form={form}
        availability={{ ...availability, id: availabilityId }}
        onSubmitForm={onSubmitForm}
        isSubmitting={isSubmitting}
        rescheduling={rescheduling}
        packageInstanceId={packageInstanceId}
        product={product}
        contactInfoInitialValues={persistentValue}
        userId={user.id}
        coach={user}
        isEmbedded={!!iframe || mobileSession}
        showPromoCodeOption={showPromoCodeOption}
        isoSlotLoaded={!!isoSlot}
      />
    </FormProvider>
  );

  const editButton = (
    <div className="bg-background rounded-lg">
      <SubmitButton
        className="!px-4 !py-2 text-foreground w-auto "
        onAction={() => {
          clearSchedulerFlow();
          previousStep({}, false);
        }}
        actionTitle="Edit"
        size="regular"
        variant="ghost"
      />
    </div>
  );

  const altFirstIcon = (
    <IconWithDetails
      icon={bookingError ? <AlertIcon /> : <ClockIcon />}
      title={bookingError || date}
      subtitle={!bookingError && time}
      iconClassNames={bookingError && "bg-error/50"}
      rightElement={editButton}
    />
  );

  if (memberIds) {
    return (
      <MemberSelectorModal
        shown={true}
        hide={() => null}
        members={(memberIds as string[]).map(
          (id) => members?.find((m) => m.id === id)
        )}
      />
    );
  }

  return (
    <>
      {isoSlot && (
        <div className="mb-4 max-w-sm sm:w-[400px]">
          <BookingSchedulerDetails
            availability={availability}
            product={product}
            taxType={taxType}
            altFirstIcon={altFirstIcon}
            hideProduct={!!packageInstanceId}
            owner={schedulerOwner}
            members={members}
            canChangeMember={!!memberIds}
            timeZone={timeZone}
          />
        </div>
      )}
      {renderContent}
    </>
  );
};

interface PaymentRequestFormStepProps {
  user: SanitizedUserType;
  product: ProductType;
  taxType: TaxType;
  returnUrl: string;
  stripePublicKey: string;
  connectedAccountId: string;
  promoCode?: string;
  availabilityId: string;
  availability: SchedulerType;
  isoSlot: string;
  iframe: string;
  coupon?: CouponType;
  hasActiveCoupons?: boolean;
}

const PaymentRequestFormStep: React.FC<PaymentRequestFormStepProps> = ({
  user,
  product,
  taxType,
  returnUrl,
  stripePublicKey,
  connectedAccountId,
  promoCode,
  hasActiveCoupons,
  availabilityId,
  availability,
  iframe,
  coupon,
}) => {
  const { sharedData: scheduleFlow } = useFlow();
  const snackbar = useSnackbar();
  const router = useRouter();
  const { logger } = useLogger("payment-request-form");
  const { persistentValue } = usePersistentState("contactInfo");
  const now = DateTime.local();
  const { apiCall: createPayment, loading, error } = useApi(postCreatePayment);
  const { persistentClear: clearSchedulerFlow } = usePersistentState(
    `schedulerFlow_${availabilityId}`,
    undefined,
    true
  );

  const { email: clientIdOrEmail, firstName, lastName, isoSlot } = scheduleFlow;

  const formattedCreatePaymentError = useMemo(() => {
    const responseError = error?.response?.data?.error;
    if (responseError) {
      return getInvoiceBoundaryErrors(responseError);
    }
  }, [error]);

  const onValidate = async (success: boolean, paymentMethod: string) => {
    if (success) {
      const body = {
        email: clientIdOrEmail,
        promoCode,
        paymentMethod,
      };
      const response = await createPayment(
        { userId: user.id, productId: product.id },
        body,
        {}
      );
      const invoiceId = response?.data?.paymentId;
      if (response) {
        snackbar.showMessage("Payment processed successfully.");
        if (coupon) analytics.track("client_applied_promo_code");
        const { email, firstName, lastName, formData, phone, communication } =
          persistentValue;
        const dataToCreate = {
          email,
          firstName,
          lastName,
          phone,
          paymentId: invoiceId,
          form: formData,
          timeZone: now.zoneName,
          communication,
          memberId: scheduleFlow.memberId,
        };

        const url = `/api/users/${user.id}/book/${availabilityId}/${isoSlot}`;

        try {
          const {
            data: { appointmentId },
          } = await axios.post(url, dataToCreate);

          const params = new URLSearchParams({
            showConfirmationEmail: true,
            ...(iframe && { iframe }),
          });

          router
            .push(
              `${getCoachBaseUrl(
                user
              )}/appointments/${appointmentId}?${params.toString()}`
            )
            .then(async () => {
              await clearSchedulerFlow();
            });
        } catch (error: any) {
          const errorMessage = catchErrorClient(error, "An error occurred");
          logger.error(
            {
              error,
              organizationId: user.id,
              productId: product.id,
              paymentId: invoiceId,
              availabilityId,
              isoSlot,
              ...(!!scheduleFlow.memberId && {
                memberId: scheduleFlow.memberId,
              }),
              clientEmail: email,
            },
            "Unable to create appointment"
          );
          snackbar.showWarning(
            `The appointment could not be booked: ${errorMessage}`
          );
        }
      }
    }
  };

  const startTime = DateTime.fromISO(isoSlot);
  const endTime = startTime.plus({ minutes: availability?.duration });
  const { date, time } = getAppointmentDateAndTime(startTime, endTime);

  const handleClickButtonError = () => {
    router.push(getCoachBaseUrl(user));
  };

  const { previousStep } = useFlow();

  useEffect(() => {
    if (!persistentValue?.email) {
      previousStep({});
    }
  }, [persistentValue?.email]);

  return (
    <div className="w-full sm:grid grid-cols-12 gap-4 mb-24">
      <div className="mb-8 sm:col-start-3 sm:col-span-8 text-foreground/50 whitespace-pre-wrap text-center">
        {product.description}
      </div>
      <div className="sm:col-start-3 sm:col-span-8">
        <DetailsWrapper>
          <IconWithDetails
            icon={<ClockIcon />}
            title={`${availability.duration}-minute appointment`}
            subtitle={DateTime.local().offsetNameLong}
          />
          <IconWithDetails
            icon={<CalendarIcon />}
            title={date}
            subtitle={time}
          />
          {product && (
            <ProductDetails
              taxType={taxType}
              product={product}
              coupon={coupon}
            />
          )}
        </DetailsWrapper>
      </div>
      <div className="sm:col-start-3 sm:col-span-8">
        <PaymentRequestForm
          userId={user.id}
          clientIdOrEmail={clientIdOrEmail}
          firstName={firstName}
          lastName={lastName}
          returnUrl={returnUrl}
          stripePublicKey={stripePublicKey}
          connectedAccountId={connectedAccountId}
          onValidate={onValidate}
          isLoading={loading}
          showCouponInput={hasActiveCoupons}
          error={formattedCreatePaymentError?.description}
          errorButtonLabel="Visit coach profile"
          onClickButtonError={handleClickButtonError}
        />
      </div>
    </div>
  );
};

interface SchedulerFlowProps {
  data: {
    user: SanitizedUserType;
    userId: string;
    availabilityId: string;
    availability: SchedulerType;
    schedulerOwner: SanitizedAccountType;
    hasActiveCoupons: boolean;
    stripePublicKey: string;
    coupon?: CouponType;
    contact?: ContactFormInfoType;
    members?: SanitizedAccountType[];
    clientOwnerId?: string;
    metaInfo: MetaInfoType;
    appointment?: AppointmentType;
    packageInstance?: PackageInstanceType;
    product?: ProductType;
    taxType?: TaxType;
    isBookingInFutureCycles?: boolean;
    // when return this value via server side, it always return as string, even
    // if it's a date object. So we need to convert it to a date object on the FE
    previousApptDate?: string;
  };
}

const SchedulerFlow: NextPage<SchedulerFlowProps> = ({ data }) => {
  const router = useRouter();

  useEffect(() => {
    function reloadPageAfterOneHour() {
      if (!document.hidden && nowPlus1Hour < new Date()) {
        location.reload();
      }
    }

    document.addEventListener("visibilitychange", reloadPageAfterOneHour);
    return () =>
      document.removeEventListener("visibilitychange", reloadPageAfterOneHour);
  }, []);

  useEffect(() => {
    if (data?.userId && data?.availabilityId) {
      analytics.track({
        userId,
        event: "Scheduler Visited by Client",
        properties: {
          availabilityId,
          embedded: isEmbedded,
        },
      });
    }
  }, []);

  const { packageInstance: packageInstanceCard } = usePublicPackageInstance(
    data?.userId,
    data?.packageInstance?.id
  );

  if (!data) return null;

  const {
    availability,
    availabilityId,
    schedulerOwner,
    user,
    userId,
    product,
    taxType,
    stripePublicKey,
    hasActiveCoupons,
    coupon,
    contact,
    members,
    packageInstance,
    clientOwnerId,
    metaInfo,
    previousApptDate,
  } = data;
  const currentPackageInstanceCycle = getCurrentCycle(packageInstanceCard);

  const {
    query: {
      rescheduling,
      iframe,
      packageInstanceId,
      clientId,
      showFirstStep = "true",
      clientParentId,
      currentMemberId,
      memberId,
    },
  } = router;
  const { packageInstanceAvailableCycles } = getFullAndAvailableCyclesFromNow(
    packageInstanceCard,
    previousApptDate ? new Date(previousApptDate) : undefined
  );
  const isBookingInFutureCycles =
    !rescheduling && !!currentPackageInstanceCycle?.isFull;
  const isEmbedded = !!iframe;
  const shouldShowFirstStep =
    showFirstStep === "true" && (!!product || availability?.description);

  const nowPlus1Hour = new Date(new Date().getTime() + 1 * 60 * 60 * 1000);

  const { metaDescription, metaHeaderImage } = metaInfo ?? {};
  const metaHeaderDescription = getMetaDescription(
    "Book a time that works best for you",
    availability.description,
    metaDescription
  );

  const steps: Array<FlowStep> = compact([
    ...(shouldShowFirstStep
      ? [
          {
            Component: ViewAvailabilityScreen,
            getProps: () => {
              return {
                product,
                availability,
                taxType,
                packageInstanceId,
                owner: schedulerOwner,
                members,
                memberId,
              };
            },
          },
        ]
      : []),
    {
      Component: TimeSlotSelectionForm,
      getProps: () => {
        return {
          user,
          rescheduling,
          iframe,
          availabilityId,
          availability,
          schedulerOwner,
          packageInstanceId,
          clientId,
          product,
          taxType,
          members,
          clientOwnerId,
          currentMemberId,
          availablePackageInstanceCycles: packageInstanceAvailableCycles,
          isBookingInFutureCycles,
          packageInstance,
        };
      },
    },
    {
      Component: ClientBookingConfirmation,
      getProps: () => {
        return {
          availability,
          availabilityId,
          schedulerOwner,
          user,
          userId,
          product,
          rescheduling,
          iframe,
          packageInstanceId,
          taxType,
          clientId,
          showPromoCodeOption: hasActiveCoupons,
          clientParentId,
          contact,
          members,
        };
      },
    },
    ...(product
      ? [
          {
            Component: PaymentRequestFormStep,
            getProps: () => {
              return {
                user,
                product,
                coupon,
                taxType,
                returnUrl:
                  (typeof window !== "undefined" &&
                    window?.location?.toString()) ||
                  "",
                connectedAccountId: user.stripe.stripe_user_id,
                stripePublicKey,
                clientId,
                promoCode: router?.query?.promoCode,
                availabilityId,
                availability,
                iframe,
                hasActiveCoupons,
              };
            },
          },
        ]
      : []),
  ]);

  return (
    <ClientLayout>
      <MetaHead
        title={availability.title}
        subTitle={`${availability.duration}-minute appointment`}
        titleContainsPractice={false}
        description={metaHeaderDescription}
        image={metaHeaderImage}
        robots="noindex"
      />
      <ClientContainerLayout
        coach={user}
        icon={availability.icon}
        title={availability.title}
        beforeTitle={
          rescheduling && (
            <ReschedulingNotice userId={userId} appointmentId={rescheduling} />
          )
        }
      >
        <FlowWithSteps
          stateName={`schedulerFlow_${availabilityId}`}
          steps={steps}
        />
      </ClientContainerLayout>
      <ClientFooterLayout coach={user} />
    </ClientLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const {
    clientId: clientIdFromQuery,
    packageInstanceId,
    userId,
    rescheduling,
  } = context.query;

  let packageInstance: any = null;
  let clientOwnerId: string;

  let clientId = clientIdFromQuery;
  let previousApptDate: Date | undefined = undefined;
  if (rescheduling) {
    const orgDoc = await getDocumentBySlugOrId("users", userId as string, db);
    const appointment = await orgDoc.ref
      .collection("appointments")
      .doc(rescheduling as string)
      .get();

    clientId = appointment.data()?.contactId || clientIdFromQuery;
    previousApptDate = appointment.data()?.start?.toDate();
  }

  if (clientId) {
    clientOwnerId = await Authz.get.user.that.owns.contact(clientId as string);
  }

  if (clientId && packageInstanceId) {
    const orgDoc = await getDocumentBySlugOrId("users", userId as string, db);
    const packageInstanceDocRef = orgDoc.ref
      .collection("packageInstances")
      .doc(packageInstanceId as string);
    const packageInstanceDoc = await packageInstanceDocRef.get();
    if (packageInstanceDoc.exists) {
      packageInstance = {
        ...packageInstanceDoc.data(),
        id: packageInstanceDoc.id,
      };
    }
  }

  return getServerSidePropsHelper(context, db, async (product, user) => {
    const { id: userId } = user;
    const orgDocRef = db.collection("users").doc(userId as string);
    const { promoCode } = context.query as Record<string, string>;
    const contact = await getContactFormInfo(context, orgDocRef);

    const couponsDocRef = await orgDocRef
      .collection("coupons")
      .where("status", "==", "active")
      .where("internal", "==", false)
      .get();

    const taxType = await getDocData("taxTypes", product?.payment.taxTypeId);
    const coupons: CouponType[] = couponsDocRef.docs.map((cpn) => {
      return { ...cpn.data(), id: cpn.id };
    });

    const foundCoupon =
      promoCode &&
      coupons?.find((cpn: CouponType) => cpn.promotionCode === promoCode);

    const metaInfo = await getCompanyMetaInformation(user);

    return {
      taxType,
      coupon: foundCoupon,
      hasActiveCoupons: couponsDocRef.size > 0,
      stripePublicKey: `${process.env.stripe_public_key}`,
      promoCode,
      contact,
      packageInstance,
      clientOwnerId,
      metaInfo,
      previousApptDate,
    };
  });
};

export default SchedulerFlow;
